import React from "react";
import Particle from "./Particles";
import Background from "./background";
export default function Landing() {
    return (
        <main>
            <Background/>
            <Particle/>
        </main>

        
        
    );
}